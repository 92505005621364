<template>
  <b-button
      v-b-tooltip.hover.top="this.label"
      variant="outline-success"
      class="m-1 warehouse-download-button"
      :disabled="disabled"
      :href="url"
  >
    {{ this.label }}
  </b-button>
</template>

<script>
import {
  BButton, VBTooltip,
} from 'bootstrap-vue'

export default {
  components: {
    BButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    disabled: {
      type: Boolean,
      default: () => {},
    },
    shipmentId: {
      type: Number,
      default: () => {},
    },
    label: {
      type: String,
      default: () => {},
    },
    type: {
      type: String,
      default: () => {},
    },
    declarationType: {
      type: String,
      default: 'h7',
    },
  },
  data() {
    return {
      url: `${process.env.VUE_APP_API_URL}/v1/companies/${this.$activeCompany().data.company.id}/shipments/${this.shipmentId}/declarations/${this.type}/${this.declarationType}/excel-for-warehouse`,
    }
  },
}
</script>
