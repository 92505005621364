<template>
  <b-modal
    :id="this.$attrs.id"
    v-model="isVisible"
    title="Resend declarations to customs"
    size="lg"
    @show="loadData"
  >
    <div
      v-if="loading"
      class="text-center py-3"
    >
      <b-spinner />
    </div>
    <div v-else>
      <div v-if="!declarationsCount">
        <h4 class="pb-1">
          There are no declarations to send
        </h4>
      </div>
      <div v-else>
        <validation-observer ref="resendDeclarations">
          <h4 class="pb-1">
            You are about send {{ declarationsCount }} declarations to customs. Only declarations without MRN will be sent.
          </h4>
          <b-form-group
            v-if="customs === 'RO'"
            label="Previous document category"
            label-for="previous_document_category"
          >
            <validation-provider
              #default="{ errors }"
              name="Choose document category"
              rules="required"
            >
              <b-form-select
                id="previous_document_category"
                v-model="previous_document_category"
                :options="previous_document_categories"
                placeholder="Choose document type"
                :state="errors.length > 0 ? false:null"
              />
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Previous document type"
            label-for="previous_document_type"
          >
            <validation-provider
              #default="{ errors }"
              name="Choose document type"
              rules="required"
            >
              <b-form-select
                id="previous_document_type"
                v-model="previous_document_type"
                :options="previous_document_types"
                placeholder="Choose document type"
                :disabled="loadingCustomsData"
                :state="errors.length > 0 ? false:null"
              />
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Previous document reference"
            label-for="previous_document_reference"
          >
            <validation-provider
              #default="{ errors }"
              name="Previous document reference"
              rules="required"
            >
              <b-form-input
                id="previous_document_reference"
                v-model="previous_document_reference"
                placeholder="Previous document reference"
                autocomplete="off"
                :state="errors.length > 0 ? false:null"
              />
            </validation-provider>
          </b-form-group>
        </validation-observer>
      </div>
    </div>

    <template #modal-footer="{ close }">
      <b-col v-if="declarationsCount">
        <b-button
          variant="secondary"
          class="mr-2"
          @click="close"
        >
          Cancel
        </b-button>
      </b-col>
      <b-col
        v-if="declarationsCount"
        class="text-right"
      >
        <b-button
          variant="primary"
          @click="validateSend()"
        >
          Confirm
        </b-button>
      </b-col>
      <b-col
        v-if="!declarationsCount && !loading"
        class="text-right"
      >
        <b-button
          variant="secondary"
          class="mr-2"
          @click="close"
        >
          Close
        </b-button>
      </b-col>
    </template>
  </b-modal>
</template>
<script>
import { BButton, BCol, BFormGroup, BFormInput, BFormSelect, BModal, BSpinner } from 'bootstrap-vue'
import handleError from '@/views/components/errorHandler'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Vue from 'vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BCol,
    BButton,
    BFormInput,
    BFormSelect,
    BFormGroup,
    BModal,
    BSpinner,
  },
  props: {
    customs: {
      type: String,
      default: null,
    },
    shipment: {
      type: Object,
      required: true,
    },
    eventHub: {
      default: () => new Vue(),
    },
    declarationType: {
      type: String,
      default: 'h7',
    },
    status: {
      type: String,
      default: 'needs_attention',
    },
  },
  data() {
    return {
      isVisible: false,
      loading: false,
      loadingCustomsData: false,
      previous_document_type: null,
      previous_document_category: '',
      previous_document_types: [],
      previous_document_categories: [
        { value: 'X', text: 'Summary Declaration' },
        { value: 'Y', text: 'Initial Declaration' },
        { value: 'Z', text: 'Previous Document' },
      ],
      previous_document_reference: '',
      declarationsCount: 0,
    }
  },
  methods: {
    loadData() {
      this.loading = true
      this.loadingCustomsData = true
      this.getCustomsData()
      this.getResendDeclarations()
    },
    getCustomsData() {
      this.$http.get(`/v1/customs/previous-document-types/${this.customs}`)
        .then(response => {
          this.loadingCustomsData = false
          this.previous_document_types = [{ value: null, text: '----select----' }]
          this.previous_document_types = this.previous_document_types.concat(response.data.data.map(previousDocumentType => ({
            value: previousDocumentType.type,
            text: previousDocumentType.name,
          })))
        })
    },
    getResendDeclarations() {
      this.$http.get(`/v1/companies/${this.shipment.company.id}/shipments/${this.shipment.id}/${this.declarationType}/resend-declarations/${this.status}`)
        .then(response => {
          this.declarationsCount = response.data.total
          this.previous_document_type = response.data.previous_document_type
          this.previous_document_reference = response.data.previous_document_reference
          this.previous_document_category = response.data.previous_document_category
          this.loading = false
        })
    },
    validateSend() {
      this.$refs.resendDeclarations.validate().then(success => {
        if (!success) {
          return
        }
        this.submit()
      })
    },
    submit() {
      this.$http.post(`/v1/companies/${this.shipment.company.id}/shipments/${this.shipment.id}/${this.declarationType}/resend-declarations/${this.status}`, {
        previous_document_type: this.previous_document_type,
        previous_document_reference: this.previous_document_reference,
        previous_document_category: this.previous_document_category,
      })
        .then(() => {
          this.previous_document_type = null
          this.previous_document_category = ''
          this.previous_document_reference = ''
          this.isVisible = false

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Sending declarations to customs',
              icon: 'EditIcon',
              variant: 'success',
            },
          })

          this.eventHub.$emit('refresh-statistics')
        })
        .catch(error => {
          handleError(error, this.$toast)
        })
    },
  },
}
</script>
