<template>
  <div>
    <edit-parcel-items
      v-if="selectedDeclaration"
      id="edit-parcel-items"
      :parcel="selectedParcel"
      :event-hub="eventHub"
      :declaration="selectedDeclaration"
      :shipment="shipment"
    />
    <b-row>
      <b-col
        class="mt-2"
        cols="9"
      >
        <b-card-text v-if="preparingToSend">
          Parcels that are prepared to send to customs.
        </b-card-text>
        <b-card-text v-else>
          Parcels that are waiting for customs response.
        </b-card-text>
      </b-col>
      <b-col
        v-if="!preparingToSend"
        cols="3"
      >
        <download-warehouse-excel-button
          :disabled="declarations.length === 0"
          label="Download Excel for Warehouse"
          :shipment-id="Number(shipment.id)"
          type="waitingForCustoms"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div class="overflow-x-scroll">
          <b-table
            :items="declarations"
            responsive
            :fields="fields"
            :busy="loading"
            :sort-by.sync="sortBy"
            :sort-desc.sync="isSortDirDesc"
            no-local-sorting
            show-empty
            empty-text="No matching records found"
            class="mb-0"
          >
            <template #table-busy>
              <div class="text-center my-2">
                <b-spinner class="align-middle mr-1" />
                <strong>Loading...</strong>
                <p
                  v-if="timeout > 0"
                  class="mt-1"
                >
                  It seems that declarations are actively changing status. List will be update with delay.
                </p>
              </div>
            </template>
            <template #head()="data">
              <span class="text-nowrap">
                {{ data.label }}
              </span>
            </template>

            <template #cell(actions)="data">
              <view-parcel-button
                :declaration="data.item"
                :parcel="data.item.parcel"
                :event-hub="eventHub"
              />
            </template>

            <template #cell(tracking_number)="data">
              {{ data.item.parcel.tracking_number }}
            </template>

            <template #cell(container_code)="data">
              {{ data.item.parcel.container_code }}
            </template>

            <template #cell(updated_at)="data">
              {{ data.item.updated_at | formatDate }}
            </template>

          </b-table>
        </div>
        <pagination
          v-if="!loading"
          :event-hub="eventHub"
          :per-page="perPage"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCardText,
  BCol,
  BRow,
  BTable,
  BSpinner,
} from 'bootstrap-vue'
import ViewParcelButton from '@/views/shipments/declarations/components/ViewParcelButton.vue'
import DownloadWarehouseExcelButton from '@/views/shipments/declarations/components/classes/DownloadWarehouseExcelButton.vue'
import Pagination from '@/layouts/components/Pagination/Pagination.vue'
import EditParcelItems from '@/views/shipments/declarations/modals/EditParcelItems.vue'
import axios from 'axios'

export default {
  components: {
    EditParcelItems,
    ViewParcelButton,
    BCardText,
    BCol,
    BRow,
    BTable,
    BSpinner,
    DownloadWarehouseExcelButton,
    Pagination,
  },
  props: {
    shipment: {
      type: Object,
      required: true,
    },
    eventHub: {
      type: Object,
      required: true,
    },
    preparingToSend: {
      type: Boolean,
      default: false,
    },
    declarationType: {
      type: String,
      default: 'h7',
    },
  },
  data() {
    return {
      timeout: -1,
      loading: false,
      page: 1,
      perPage: 1000,
      selectedDeclaration: null,
      selectedParcel: null,
      sortBy: null,
      isSortDirDesc: null,
      fields: [
        { key: 'actions', label: 'Actions' },
        { key: 'tracking_number', label: 'Tracking code', sortable: true },
        { key: 'container_code', label: 'Container', sortable: true },
        { key: 'lrn', label: 'LRN' },
        { key: 'mrn', label: 'MRN' },
        { key: 'updated_at', label: 'Declaration time' },
      ],
      declarations: [],
      intervalId: null,
      requestCanceller: null,
    }
  },
  computed: {
    url() {
      let url = `/v1/companies/${this.$activeCompany().data.company.id}/shipments/${this.shipment.id}/declarations/${this.declarationType}/waiting-for-customs`
      if (this.preparingToSend) {
        url = `/v1/companies/${this.$activeCompany().data.company.id}/shipments/${this.shipment.id}/declarations/${this.declarationType}/preparing-to-send`
      }
      url += `?page=${this.page}`
      url += `&per_page=${this.perPage}`
      return url
    },
  },
  watch: {
    sortBy() {
      this.fetchData()
    },
    isSortDirDesc() {
      this.fetchData()
    },
  },
  created() {
    this.eventHub.$on('update-parcel-items', () => {
      this.selectedDeclaration = null
      this.selectedParcel = null
      this.fetchData()
    })
    this.eventHub.$on('general-declaration-update', () => {
      if (!this.intervalId) {
        this.startTicker()
      }
      this.timeout = 6
      if (this.requestCanceller !== null) {
        this.requestCanceller.cancel('Request has been canceled')
      }
      this.fetchData()
    })
    this.eventHub.$on('set-view-parcel', this.viewParcel)
    this.eventHub.$on('pageChange', newPage => {
      this.page = newPage
      this.fetchData()
    })
    this.eventHub.$on('renewDeclarationList', () => {
      this.eventHub.$emit('refresh-statistics')
      this.fetchData()
    })
    this.fetchData()
    this.startTicker()
  },
  methods: {
    startTicker() {
      this.intervalId = setInterval(this.countDown, 1000)
    },
    countDown() {
      this.timeout -= 1

      if (this.timeout > 0) {
        this.loading = true
      }

      if (this.timeout === 0) {
        this.fetchData()
      }
    },
    viewParcel(declaration, parcel) {
      this.selectedDeclaration = declaration
      this.selectedParcel = parcel
    },
    fetchData(activeTab) {
      if (
        (
          !activeTab
          || (activeTab === 'waiting-customs' && !this.preparingToSend)
          || (activeTab === 'preparing-to-send' && this.preparingToSend)
        )
        && !this.loading
      ) {
        this.requestCanceller = axios.CancelToken.source()
        this.loading = true
        this.$http.get(this.url, {
          params: {
            sortBy: this.sortBy,
            sortDesc: this.isSortDirDesc,
          },
          cancelToken: this.requestCanceller.token,
        })
          .then(response => {
            this.loading = false
            this.declarations = response.data.data
            this.$props.eventHub.$emit('updateMeta', response.data.meta)
          })
      }
    },
  },
}
</script>
