<template>
  <div>
    <edit-parcel-items
      v-if="selectedEditParcel"
      id="edit-parcel-items"
      :parcel="selectedEditParcel"
      :event-hub="eventHub"
      :shipment="shipment"
    />
    <b-row>
      <b-col class="mt-2">
        <b-card-text>
          Forbidden & restricted articles - H7 not available
        </b-card-text>
      </b-col>
      <b-col class="text-right">
        <b-button
          variant="outline-info"
          class="m-1"
          :href="excelDownloadUrl"
        >
          Download as Excel
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-table
          :items="parcels"
          responsive
          :fields="fields"
          :busy="loading"
          :sort-by.sync="sortBy"
          :sort-desc.sync="isSortDirDesc"
          no-local-sorting
          show-empty
          empty-text="No matching records found"
          class="mb-0"
        >
          <template #head()="data">
            <span class="text-nowrap">
              {{ data.label }}
            </span>
          </template>

          <template #cell(actions)="data">
            <b-button
              v-b-modal.edit-parcel-items
              type="button"
              variant="warning"
              @click="editParcel(data.item)"
            >
              <feather-icon icon="EditIcon" />
            </b-button>
          </template>

          <template #cell(tracking_number)="data">
            {{ data.item.tracking_number }}
          </template>

          <template #cell(container_code)="data">
            {{ data.item.container_code }}
          </template>

          <template #cell(descriptions)="data">
            <div>
              {{ data.item.items.map(function (parcelItem) {return parcelItem.description}).join(', ') }}
            </div>
          </template>

          <template #cell(hs_codes)="data">
            <div>
              {{ data.item.items.map(function (parcelItem) {return parcelItem.hs_code}).join(', ') }}
            </div>
          </template>

          <template #cell(value)="data">
            <div
              v-if="data.item.items.length > 0"
              class="text-right"
            >
              {{ data.item.items.reduce(function (sum, parcelItem) {return sum + parseFloat(parcelItem.value)}, 0) | formatNumber(2) }}
            </div>
          </template>

        </b-table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BButton,
  BCardText,
  BCol,
  BTable,
  BRow,
} from 'bootstrap-vue'
import EditParcelItems from '@/views/shipments/declarations/modals/EditParcelItems.vue'

export default {
  components: {
    BButton,
    BCardText,
    BCol,
    BRow,
    BTable,
    EditParcelItems,
  },
  props: ['eventHub', 'shipment'],
  data() {
    return {
      loading: false,
      excelDownloadUrl: `${process.env.VUE_APP_API_URL}/v1/companies/${this.$activeCompany().data.company.id}/shipments/${this.shipment.id}/declarations/prohibited/excel`,
      selectedEditParcel: null,
      parcels: [],
      sortBy: null,
      isSortDirDesc: null,
      fields: [
        { key: 'actions', label: 'Actions' },
        { key: 'tracking_number', label: 'Tracking code', sortable: true },
        { key: 'container_code', label: 'Container', sortable: true },
        { key: 'descriptions', label: 'Description' },
        { key: 'hs_codes', label: 'HS code' },
        { key: 'value', label: 'Value' },
      ],
    }
  },
  watch: {
    sortBy() {
      this.fetchData()
    },
    isSortDirDesc() {
      this.fetchData()
    },
  },
  created() {
    this.fetchData()
    this.eventHub.$on('update-parcel-items', this.updateParcelItems)
    this.eventHub.$on('general-declaration-update', this.fetchData)
  },
  methods: {
    updateParcelItems() {
      this.selectedEditParcel = null
      this.fetchData()
      this.eventHub.$emit('refresh-statistics')
    },
    editParcel(data) {
      this.selectedEditParcel = data
    },
    fetchData(activeTab) {
      if ((!activeTab || activeTab === 'prohibited') && !this.loading) {
        this.loading = true
        this.$http.get(`/v1/companies/${this.$activeCompany().data.company.id}/shipments/${this.shipment.id}/declarations/prohibited`, {
          params: {
            sortBy: this.sortBy,
            sortDesc: this.isSortDirDesc,
          },
        })
          .then(response => {
            this.loading = false
            this.parcels = response.data.data
          })
      }
    },
  },
}
</script>
