<template>
  <b-modal
    id="modal-status-information"
    :title="item.parcel.tracking_number + ' Errors'"
    size="lg"
    scrollable
  >
    <div>
      <b-table
        :items="item.status_info['errors']"
        :fields="fields"
      >
        <template #cell(message)="data">
          {{ data.item.message || 'error message is undefined' }}
        </template>
      </b-table>
    </div>
  </b-modal>
</template>
<script>

import { BTable } from 'bootstrap-vue'

export default {
  components: {
    BTable,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      fields: [
        { key: 'message', label: 'Error message' },
      ],
    }
  },
}
</script>
