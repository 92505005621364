var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":this.$attrs.id,"title":"Resend declarations to customs","size":"lg"},on:{"show":_vm.loadData},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var close = ref.close;
return [(_vm.declarationsCount)?_c('b-col',[_c('b-button',{staticClass:"mr-2",attrs:{"variant":"secondary"},on:{"click":close}},[_vm._v(" Cancel ")])],1):_vm._e(),(_vm.declarationsCount)?_c('b-col',{staticClass:"text-right"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.validateSend()}}},[_vm._v(" Confirm ")])],1):_vm._e(),(!_vm.declarationsCount && !_vm.loading)?_c('b-col',{staticClass:"text-right"},[_c('b-button',{staticClass:"mr-2",attrs:{"variant":"secondary"},on:{"click":close}},[_vm._v(" Close ")])],1):_vm._e()]}}]),model:{value:(_vm.isVisible),callback:function ($$v) {_vm.isVisible=$$v},expression:"isVisible"}},[(_vm.loading)?_c('div',{staticClass:"text-center py-3"},[_c('b-spinner')],1):_c('div',[(!_vm.declarationsCount)?_c('div',[_c('h4',{staticClass:"pb-1"},[_vm._v(" There are no declarations to send ")])]):_c('div',[_c('validation-observer',{ref:"resendDeclarations"},[_c('h4',{staticClass:"pb-1"},[_vm._v(" You are about send "+_vm._s(_vm.declarationsCount)+" declarations to customs. Only declarations without MRN will be sent. ")]),(_vm.customs === 'RO')?_c('b-form-group',{attrs:{"label":"Previous document category","label-for":"previous_document_category"}},[_c('validation-provider',{attrs:{"name":"Choose document category","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"previous_document_category","options":_vm.previous_document_categories,"placeholder":"Choose document type","state":errors.length > 0 ? false:null},model:{value:(_vm.previous_document_category),callback:function ($$v) {_vm.previous_document_category=$$v},expression:"previous_document_category"}})]}}],null,false,3231790775)})],1):_vm._e(),_c('b-form-group',{attrs:{"label":"Previous document type","label-for":"previous_document_type"}},[_c('validation-provider',{attrs:{"name":"Choose document type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"previous_document_type","options":_vm.previous_document_types,"placeholder":"Choose document type","disabled":_vm.loadingCustomsData,"state":errors.length > 0 ? false:null},model:{value:(_vm.previous_document_type),callback:function ($$v) {_vm.previous_document_type=$$v},expression:"previous_document_type"}})]}}])})],1),_c('b-form-group',{attrs:{"label":"Previous document reference","label-for":"previous_document_reference"}},[_c('validation-provider',{attrs:{"name":"Previous document reference","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"previous_document_reference","placeholder":"Previous document reference","autocomplete":"off","state":errors.length > 0 ? false:null},model:{value:(_vm.previous_document_reference),callback:function ($$v) {_vm.previous_document_reference=$$v},expression:"previous_document_reference"}})]}}])})],1)],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }